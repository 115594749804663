import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import Layout from '../components/layout'
import SEO from '../components/seo'
import styles from './teacher.module.css'

const numberOfGuides = 4

const TeacherPage = ({ data }) => {
  const { formatMessage } = useIntl()

  const renderGuides = () => {
    const renderedGuides = []
    for (let i = 1; i <= numberOfGuides; i++) {
      const downloadablePath = formatMessage({
        id: `pages.teacher.sections.guides.guide${i}.file`,
      })
      const hasDownloadableFile = downloadablePath !== 'NULL'

      renderedGuides.push(
        <div
          key={i}
          className="w-full md:w-1/2 lg:w-1/4 px-12 lg:px-8 mt-8 lg:mt-0 flex flex-col items-center"
        >
          <div className="w-48 lg:w-full">
            <Img
              alt={`Download 0${i}`}
              fluid={data[`download0${i}`].childImageSharp.fluid}
            />
          </div>

          <p className="text-white mt-2 flex-grow">
            <FormattedMessage
              id={`pages.teacher.sections.guides.guide${i}.text`}
            />
          </p>

          {hasDownloadableFile && (
            <a className="btn mt-8" href={downloadablePath} download>
              <FormattedMessage
                id={`pages.teacher.sections.guides.guide${i}.button`}
              />
            </a>
          )}

          {!hasDownloadableFile && (
            <span className="inline-block mt-8 text-yellow-light font-fredoka">
              <FormattedMessage id="general.comingSoon" />
            </span>
          )}
        </div>
      )
    }
    return renderedGuides
  }

  return (
    <Layout>
      <SEO
        lang={formatMessage({ id: 'lang' })}
        title={formatMessage({ id: 'pages.teacher.title' })}
        description={formatMessage({ id: 'pages.teacher.description' })}
      />

      <section className={styles.sectionLanding}>
        <div className="custom-container pt-32 pb-40 lg:pb-48">
          <div className="badge">
            <Img alt="Teacher badge" fluid={data.badge.childImageSharp.fluid} />
          </div>

          <h1 className="heading text-yellow-light uppercase mt-4">
            <FormattedMessage id="pages.teacher.title" />
          </h1>

          <p className="mt-8 text-yellow-light text-lg font-semibold">
            <FormattedMessage id="pages.teacher.sections.landing.title" />
          </p>

          <p className="mt-4 text-white">
            <FormattedMessage id="pages.teacher.sections.landing.p1" />
          </p>

          <p className="text-white">
            <FormattedMessage id="pages.teacher.sections.landing.p2" />
          </p>

          <a
            className="btn inline-block mt-8"
            href="https://profesores.vikidz.io/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <FormattedMessage id="pages.teacher.sections.landing.button" />
          </a>
        </div>

        <div className="relative custom-container max-w-5xl">
          <div className={styles.teacher01}>
            <Img
              alt="Teacher 01"
              fluid={data.teacher01.childImageSharp.fluid}
            />
          </div>

          <div className={styles.lines}>
            <Img alt="Lines" fluid={data.lines.childImageSharp.fluid} />
          </div>

          <div className={styles.teacher02}>
            <Img
              alt="Teacher 02"
              fluid={data.teacher02.childImageSharp.fluid}
            />
          </div>
        </div>
      </section>

      <section className="bg-blue-darkest pt-24 pb-40">
        <div className="custom-container custom-container--extralarge">
          <div className="w-4/5 md:w-2/3 mx-auto">
            <h2 className="heading text-yellow-light">
              <FormattedMessage id="pages.teacher.sections.guides.title" />
            </h2>
          </div>

          <div className="flex items-stretch justify-between flex-wrap mt-8">
            {renderGuides()}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    badge: file(relativePath: { eq: "badges/teacher-color.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    teacher01: file(relativePath: { eq: "teacher/teacher-01.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    teacher02: file(relativePath: { eq: "teacher/teacher-02.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lines: file(relativePath: { eq: "teacher/lines.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    download01: file(relativePath: { eq: "teacher/download-01.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    download02: file(relativePath: { eq: "teacher/download-02.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    download03: file(relativePath: { eq: "teacher/download-03.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    download04: file(relativePath: { eq: "teacher/download-04.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default TeacherPage
